import React, { useState } from 'react';
import {
  Modal, Form, Select, DatePicker, Input, Button, Row, Col,
} from 'antd';
import _ from 'lodash';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';

import locale from 'antd/es/date-picker/locale/th_TH'
import provinceData from '../../utils/staticData/provinceData';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default Form.create()(
  ({
    visible,
    handleSearch,
    handleCancel,
    form: { getFieldDecorator, validateFields, resetFields, setFieldsValue },
    renderInputFields,
  }) => {
    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }

        if (fieldsValue['name'] || fieldsValue['name_2']) {
          fieldsValue['name'] = (fieldsValue['name'] ? fieldsValue['name'] : "") + " " + (fieldsValue['name_2'] ? fieldsValue['name_2'] : "");
        }
        fieldsValue = _.omit(fieldsValue, ['name_2']);

        if (fieldsValue['userDetail'] || fieldsValue['userDetail_2']) {
          fieldsValue['userDetail'] = (fieldsValue['userDetail'] ? fieldsValue['userDetail'] : "") + " " + (fieldsValue['userDetail_2'] ? fieldsValue['userDetail_2'] : "");
        }
        fieldsValue = _.omit(fieldsValue, ['userDetail_2']);

        if (fieldsValue['adminDetail'] || fieldsValue['adminDetail_2']) {
          fieldsValue['adminDetail'] = (fieldsValue['adminDetail'] ? fieldsValue['adminDetail'] : "") + " " + (fieldsValue['adminDetail_2'] ? fieldsValue['adminDetail_2'] : "");
        }
        fieldsValue = _.omit(fieldsValue, ['adminDetail_2']);

        if (fieldsValue['approver'] || fieldsValue['approver_2']) {
          fieldsValue['approver'] = (fieldsValue['approver'] ? fieldsValue['approver'] : "") + " " + (fieldsValue['approver_2'] ? fieldsValue['approver_2'] : "");
        }
        fieldsValue = _.omit(fieldsValue, ['approver_2']);

        if (fieldsValue['ReporterName'] || fieldsValue['ReporterName_2']) {
          fieldsValue['ReporterName'] = (fieldsValue['ReporterName'] ? fieldsValue['ReporterName'] : "") + " " + (fieldsValue['ReporterName_2'] ? fieldsValue['ReporterName_2'] : "");
        }
        fieldsValue = _.omit(fieldsValue, ['ReporterName_2']);

        if (fieldsValue['plate'] || fieldsValue['plate_2']) {
          fieldsValue['plate'] = (fieldsValue['plate'] ? fieldsValue['plate'] : "") + " " + (fieldsValue['plate_2'] ? fieldsValue['plate_2'] : "");
        }
        fieldsValue = _.omit(fieldsValue, ['plate_2']);


        // Should format date value before submit.
        const filterFieldsValue = _.omit(fieldsValue, ['mocId']);

        const rangeValue = fieldsValue['date-range'];
        const values = {
          ...filterFieldsValue,
        };



        if (rangeValue && rangeValue[0] && rangeValue[1]) {
          values.start_search_date = rangeValue[0].format('YYYY-MM-DD HH:mm:ss');
          values.end_search_date = rangeValue[1].format('YYYY-MM-DD HH:mm:ss');
        }

        const rangeValue2 = fieldsValue['date-range2'];


        if (rangeValue2 && rangeValue2[0] && rangeValue2[1]) {
          values.start_search_date2 = rangeValue2[0].format('YYYY-MM-DD HH:mm:ss');
          values.end_search_date2 = rangeValue2[1].format('YYYY-MM-DD HH:mm:ss');
        }


        if (fieldsValue['date_picker']) {
          values.date_picker = fieldsValue['date_picker'].format('YYYY-MM-DD');
        }



        // force to return first page every time
        values.page = 1;

        handleSearch(_.omit(values, ['date-range', 'mock', 'date-range2']));
      });
    };

    const handleClear = (e) => {
      resetFields();
      handleSubmit(e);
    };

    const [isLockSelect, setIsLockSelect] = useState(false);

    return (

      <Modal
        title="ค้นหา"
        visible={visible}
        style={{ minWidth: '800px' }}
        onCancel={handleCancel}
        footer={[
          <Button key="clear" type="danger" onClick={handleClear}>
            ล้างค่าการค้นหา
          </Button>,
          <Button key="back" onClick={handleCancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            ค้นหา
          </Button>,
        ]}
      >
        <Form
          // layout="inline"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
        // onSubmit={handleSubmit}
        >
          {/* <Form.Item label="หมายเลขตู้">
            {getFieldDecorator('kioskId')(<Input />)}
          </Form.Item> */}

          {_.map(renderInputFields, (o, i) => {

            if (_.get(o, ['key']) === 'userDetail' || _.get(o, ['key']) === 'adminDetail' || _.get(o, ['key']) === 'name' || _.get(o, ['key']) === 'approver' || _.get(o, ['key']) === 'ReporterName') {
              return (
                <Form.Item key={i} label={o.label}>
                  <Row gutter={8}>
                    <Col span={12}>
                      {getFieldDecorator(o.key)(
                        <Input placeholder={"ชื่อ"} />
                      )}
                    </Col>
                    <Col span={12}>
                      {getFieldDecorator(o.key + '_2')(
                        <Input placeholder={"นามสกุล"} />
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              );
            }

            if (_.get(o, ['key']) === 'plate') {
              return (
                <Form.Item key={i} label={o.label}>
                  <Row gutter={8}>
                    <Col span={8}>
                      {getFieldDecorator(o.key)(
                        <Input placeholder={"กก"} />
                      )}
                    </Col>
                    <Col span={16}>
                      {getFieldDecorator(o.key + '_2')(
                        <Input placeholder={"1234"} />
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              );
            }

            if (_.get(o, ['type']) === 'select') {
              const options = _.get(o, ['options']);

              // Find the option with selected = true, if any
              const defaultSelectedOption = options.find(option => option.selected === true);

              return (
                <Form.Item key={i} label={o.label}>
                  {getFieldDecorator(o.key, {
                    initialValue: defaultSelectedOption ? defaultSelectedOption.value : undefined,
                  })(
                    <Select>
                      {_.map(options, (op, i) => (
                        <Option key={i} value={op.value}>
                          {op.title}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              );
            }


            if (_.get(o, ['type']) === 'select2') {
              const options = _.get(o, ['options']);
              const isFilterByValue = _.get(o, ['isFilterByValue'], false);
              // Find the option with selected = true, if any
              const defaultSelectedOption = options.find(option => option.selected === true);
              const filterOption = (input, option) => {
                const inputValue = option.props.value.toLowerCase();
                const inputLower = input.toLowerCase();
                const titleLower = option.props.children.toLowerCase();

                if (isFilterByValue) {
                  return inputValue.indexOf(inputLower) >= 0 || titleLower.indexOf(inputLower) >= 0;
                } else {
                  return titleLower.indexOf(inputLower) >= 0;
                }
              };

              const handleSelectChange = (value, key) => {
                // console.log('Selected value is', value);
                // console.log('Selected option is', key);
                if (key === 'plateType' && value === '2') {
                  setFieldsValue({ vehTypeCode: '' });
                  setIsLockSelect(true);
                } else if (key === 'plateType' && (value === '' || value === '1')) {

                  setIsLockSelect(false);
                }
              }

              const options2 = [
                {
                  title: 'ทั้งหมด',
                  value: '',
                },
                {
                  title: 'รถโดยสาร ประจำทาง',
                  value: '10',
                },
                {
                  title: 'รถขนาดเล็ก',
                  value: '20',
                },
                {
                  title: 'รถโดยสาร ไม่ประจำทาง',
                  value: '30',
                },
                {
                  title: 'รถโดยสาร ส่วนบุคคล',
                  value: '40',
                },
                {
                  title: 'รถบรรทุก ไม่ประจำทาง',
                  value: '70',
                },
                {
                  title: 'รถบรรทุก ส่วนบุคคล',
                  value: '80',
                },
              ]

              return (
                <Form.Item key={i} label={o.label}>
                  {getFieldDecorator(o.key, {
                    initialValue: defaultSelectedOption ? defaultSelectedOption.value : undefined,
                  })(
                    <Select
                      showSearch // Enable the search box
                      filterOption={filterOption}

                      onChange={(value) => handleSelectChange(value, o.key)}
                    >

                      {o.key === 'vehTypeCode' && isLockSelect ? (
                        options2.map((op, i) => (
                          <Select.Option key={i} value={op.value}>
                            {op.title}
                          </Select.Option>
                        ))
                      ) :
                        options.map((op, i) => (
                          <Select.Option key={i} value={op.value}>
                            {op.title}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  )}
                </Form.Item>
              );
            }



            if (_.get(o, ['type']) === 'rangepicker') {
              return (
                <Form.Item key={i} label={o.label}>
                  {getFieldDecorator(o.key)(
                    <RangePicker showTime locale={locale} />
                  )}
                </Form.Item>
              );
            }

            if (_.get(o, ['type']) === 'rangepickernotime') {
              return (
                <Form.Item key={i} label={o.label}>
                  {getFieldDecorator(o.key)(
                    <RangePicker locale={locale} />
                  )}
                </Form.Item>
              );
            }

            if (_.get(o, ['type']) === 'datepicker') {
              return (
                <Form.Item key={i} label={o.label}>
                  {getFieldDecorator(o.key)(
                    <DatePicker locale={locale} />
                  )}
                </Form.Item>
              );
            }

            if (_.get(o, ['type']) === 'label') {
              return (
                <span style={{ color: 'red', display: 'block', textAlign: 'center' }}>{o.label}</span>
              );
            }

            return (
              <Form.Item key={i} label={o.label}>
                {getFieldDecorator(o.key)(
                  <Input placeholder={_.get(o, 'placeholder', undefined)} />
                )}
              </Form.Item>

            );
          })}
          {!renderInputFields && (
            // <>
            //   <Form.Item label="หมายเลขตู้">
            //     {getFieldDecorator('kiosk')(<Input />)}
            //   </Form.Item>
            //   <Form.Item label="หมวดทะเบียนรถ">
            //     {getFieldDecorator('plate1')(<Input />)}
            //   </Form.Item>
            //   <Form.Item label="เลขทะเบียน">
            //     {getFieldDecorator('plate2')(<Input />)}
            //   </Form.Item>
            //   <Form.Item label="เลขที่ใบเสร็จ">
            //     {getFieldDecorator('invoice_number')(<Input />)}
            //   </Form.Item>
            //   <Form.Item label="เลขประจำตัวประชาชนผู้ทำรายการ">
            //     {getFieldDecorator('docNo')(<Input />)}
            //   </Form.Item>
            //   <Form.Item label="เลขประจำตัวประชาชนเจ้าของรถ">
            //     {getFieldDecorator('ownIdNo')(<Input />)}
            //   </Form.Item>
            //   <Form.Item label="ช่วงเวลาการทำรายการ">
            //     {getFieldDecorator('date-range', {
            //       rules: [
            //         {
            //           type: 'array',
            //         },
            //       ],
            //     })(<RangePicker showTime locale={locale} />)}
            //   </Form.Item>
            //   <Form.Item label="หมายเลขอ้างอิง 1">
            //     {getFieldDecorator('reference_1')(<Input />)}
            //     {/* <Input /> */}
            //   </Form.Item>
            //   <Form.Item label="ประเภทรถ">
            //     {getFieldDecorator('vehTypeCode')(
            //       <Select
            //         placeholder="เลือกประเภทรถ"
            //       // onChange={this.handleSelectChange}
            //       >
            //         {_.map(vehicleTypeData, (o, i) => (
            //           <Option key={i} value={o.id}>
            //             {o.name}
            //           </Option>
            //         ))}
            //       </Select>,
            //     )}
            //   </Form.Item>
            //   <Form.Item label="รหัสตู้">
            //     {getFieldDecorator('kiosk_code')(<Input />)}
            //   </Form.Item>
            //   <Form.Item label="เลขคุมเครื่องหมาย">
            //     {getFieldDecorator('kioskPaper_reference')(<Input />)}
            //   </Form.Item>
            // </>
            <>
              <Form.Item label="ชื่อ-นามสกุลผู้ทำรายการ">
                <Row gutter={8}>
                  <Col span={12}>
                    {getFieldDecorator('name')(<Input placeholder={"ชื่อ"} />)}
                  </Col>
                  <Col span={12}>
                    {getFieldDecorator('name_2')(<Input placeholder={"นามสกุล"} />)}
                  </Col>
                </Row>
              </Form.Item>
              {/* <Form.Item label="หมวดทะเบียนรถ">
                {getFieldDecorator('plate1')(<Input />)}
              </Form.Item> */}
              {/* <Form.Item label="เลขทะเบียน">
                {getFieldDecorator('plate')(<Input placeholder='ตัวอย่าง เช่น กก 1111, 80 1234' />)}
              </Form.Item> */}
              <Form.Item label="เลขทะเบียน">
                <Row gutter={8}>
                  <Col span={8}>
                    {getFieldDecorator('plate')(
                      <Input placeholder={"กก"} />
                    )}
                  </Col>
                  <Col span={16}>
                    {getFieldDecorator('plate_2')(
                      <Input placeholder={"1234"} />
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="จังหวัดจดทะเบียน">
                {getFieldDecorator('offRegDesc')(
                  <Select
                    showSearch // Enable the search box
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {provinceData.map((item, i) => (
                      <Select.Option key={i.province} value={item.province}>
                        {item.province}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              {/* <Form.Item label="ประเภทพรบ.">
                {getFieldDecorator("plateType")(
                  <Select>
                    <Option key={""} value={""}>
                      ทั้งหมด
                    </Option>
                    <Option key={1} value={"1"}>
                      รถยนต์ส่วนตัว
                    </Option>
                    <Option key={2} value={"2"}>
                      รถขนส่ง
                    </Option>
                  </Select>
                )}
              </Form.Item> */}
              <Form.Item label="ประเภทรถ">
                {getFieldDecorator("vehTypeCode")(
                  <Select>
                    <Option key={""} value={""}>
                      ทั้งหมด
                    </Option>
                    {/* {_.map(vehicleTypeData, (o, i) => (
                      <Option key={i} value={o.id}>
                        {o.name}
                      </Option>
                    ))} */}
                    <Option key={1} value={"01"}>
                      รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน (รย.1)
                    </Option>
                    <Option key={2} value={"02"}>
                      รถยนต์นั่ง ส่วนบุคคลเกิน 7 คน (รย.2)
                    </Option>
                    <Option key={3} value={"03"}>
                      รถยนต์บรรทุกส่วนบุคคล (รย.3)
                    </Option>
                    <Option key={4} value={"12"}>
                      รถจักรยานยนต์ส่วนบุคคล (รย.12)
                    </Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="ช่วงเวลาการทำรายการ">
                {getFieldDecorator('date-range', {
                  rules: [
                    {
                      type: 'array',
                    },
                  ],
                })(<RangePicker showTime locale={locale} />)}
              </Form.Item>
              <Form.Item label="หมายเลขอ้างอิง 1">
                {getFieldDecorator('reference_1')(<Input />)}
                {/* <Input /> */}
              </Form.Item>
              <Form.Item label="หมายเลขอ้างอิง 2">
                {getFieldDecorator('reference_2')(<Input />)}
                {/* <Input /> */}
              </Form.Item>
              <Form.Item label="รหัสตู้">
                {getFieldDecorator('kiosk_code')(<Input />)}
              </Form.Item>
              <Form.Item label="เลขที่ใบเสร็จ">
                {getFieldDecorator('invoice_number')(<Input />)}
              </Form.Item>
              <Form.Item label="เลขคุมเครื่องหมาย">
                {getFieldDecorator('kioskPaper_reference')(<Input />)}
              </Form.Item>

              <Form.Item label="หมายเลข EMS">
                {getFieldDecorator('dlt_post_tracking_no')(<Input />)}
              </Form.Item>

              <Form.Item label="ช่องทางการทำรายการ">
                {getFieldDecorator('transaction_channel')(
                  <Select>
                    <Option key={""} value={""}>
                      ทั้งหมด
                    </Option>
                    <Option key={1} value={"mobile"}>
                      Mobile Application
                    </Option>
                    <Option key={2} value={"kiosk"}>
                      ตู้รับชำระภาษี
                    </Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="ช่องทางการรับป้ายภาษี">
                {getFieldDecorator("printFlag")(
                  <Select>
                    <Option key={""} value={""}>
                      ทั้งหมด
                    </Option>
                    <Option key={1} value={"true"}>
                      ตู้ชำระภาษี
                    </Option>
                    <Option key={2} value={"false"}>
                      ไปรษณีย์
                    </Option>
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label="สถานะการต่อภาษี">
                {getFieldDecorator('is_success_search')(
                  <Select>
                    <Option key={""} value={""}>
                      ทั้งหมด
                    </Option>
                    <Option key={1} value={"pending"}>
                      รอดำเนินการ
                    </Option>
                    <Option key={2} value={"true"}>
                      สำเร็จ
                    </Option>
                    <Option key={3} value={"false"}>
                      ไม่สำเร็จ
                    </Option>
                  </Select>
                )}
              </Form.Item>
              {/* <Form.Item label="ช่องทางการทำรายการ">
                {getFieldDecorator("transaction_channel2")(
                  <Select>
                    <Option key={""} value={""}>
                      ทั้งหมด
                    </Option>
                    <Option key={3} value={"03"}>
                      รถยนต์บรรทุกส่วนบุคคล(รย.3)
                    </Option>
                    <Option key={4} value={"12"}>
                      รถจักรยานยนต์ส่วนบุคคล(รย.12)
                    </Option>
                    <Option key={1} value={"mobile"}>
                      Mobile Application
                    </Option>
                    <Option key={2} value={"kiosk"}>
                      ตู้รับชำระภาษี
                    </Option>
                  </Select>
                )}
              </Form.Item> */}

            </>
          )}
        </Form>
      </Modal>
    );
  },
);
