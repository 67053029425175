import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Select,
  Descriptions,
  Input,
  Button,
  Spin,
  Radio,
  Icon,
  List,
  Row,
  Col,
} from 'antd';
import _, { now } from 'lodash';
import get from 'lodash/get';
import { MainContext } from '../../context/main';
import styled from 'styled-components';
import moment from 'moment';
import { convertToDateBE } from '../../utils';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import QuillEditor from '../../component/QuillRichTextEditor';
import swal from 'sweetalert2';

const { Option } = Select;
const openImageInNewTab = (dataUrl) => {
  const html = `<html><body><img src="${dataUrl}" /></body></html>`;
  const blob = new Blob([html], { type: 'text/html' });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
};


export default Form.create()(
  ({
    visible,
    onSubmit = () => { },
    onCancel = () => { },
    form: { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue },
    data,
    formLoading,

  }) => {
    const { request, userProfile } = useContext(MainContext);
    const [dataImage, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields(async (err, fieldsValue) => {
        if (err) {
          return;
        }
        onSubmit(_.omit(fieldsValue));
      });
    };

    useEffect(() => {
      if (!visible) {
        resetFields();
        setData({});
      }
    }, [visible]);

    useEffect(() => {
      if (isLoading) {
        const modal = Modal.info({
          content: (
            <div style={{ textAlign: 'center' }}>
              <p>กำลังดาวน์โหลดข้อมูล กรุณารอสักครู่</p>
              <Spin style={{ display: 'block', margin: 'auto' }} />
            </div>
          ),
          okButtonProps: { style: { display: 'none' } },
          style: {
            top: '50%',
            // transform: 'translateY(-50%)',
          }
        });
      } else {
        Modal.destroyAll();
      }
    }, [isLoading]);

    // call when this modal is open
    useEffect(() => {
      const fetchData = async () => {
        if (visible) {
          try {
            setIsLoading(true);
            const res = await request.get('/usersreport/' + get(data, ['id']));
            //set data DetailReply by res.DetailReply
            data.DetailReply = res.DetailReply;
            setData(res);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          setIsLoading(false);
        }
      };
      fetchData(); // Call the async function inside the useEffect
    }, [visible, request]);

    var ImageReport = dataImage?.ImageReport ? dataImage?.ImageReport : [];

    const openFile = (fileBase64, fileName) => {
      try {
        const byteCharacters = atob(fileBase64.split(',')[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const extension = fileBase64.split(';')[0].split('/')[1].toLowerCase();
        let mimeType;

        switch (extension) {
          case 'jpg':
          case 'jpeg':
            mimeType = 'image/jpeg';
            break;
          case 'png':
            mimeType = 'image/png';
            break;
          case 'pdf':
            mimeType = 'application/pdf';
            break;
          case 'heic':
            mimeType = 'image/heic';
            break;
          default:
            mimeType = 'application/octet-stream';
            break;
        }

        const blob = new Blob(byteArrays, { type: mimeType });
        const fileUrl = URL.createObjectURL(blob);

        window.open(fileUrl);
      } catch (error) {
        swal.fire({
          title: 'ไม่สามารถเปิดไฟล์ได้',
          text: 'ไฟล์อาจเสียหาย หรือการอัพโหลดไม่สมบูรณ์',
          icon: 'error',
          confirmButtonText: 'ปิด',
        });
        console.log(error)
      }
    };

    const renderFileItem = (item) => (
      <List.Item style={styles.fileItem}>
        <div style={styles.leftContent}>
          {item.file_base64.split(';')[0].split('/')[0] === '' ? (
            item.file_base64.split(';')[0].split('/')[1].toLowerCase() === '' ? (
              <Icon type="file" style={styles.fileIcon} />
            ) : (
              <img src={item.file_base64} alt={item.file_name} style={styles.fileIcon} height={60} />
            )
          ) : (
            <Icon type="file" style={styles.fileIcon} />
          )}

          {/* {item.file_base64.split(';')[0].split('/')[0]} */}
          <span style={styles.fileName}>{item.file_name}</span>
        </div>
        <div style={styles.rightContent}>
          <Button type="link" onClick={() => openFile(item.file_base64, item.file_name)}>
            เรียกดู
          </Button>
        </div>
      </List.Item>
    );



    const renderEmpty = () => {
      if (isLoading) return <Spin />;
      return (
        <div style={styles.emptyContainer}>
          ไม่มีเอกสารประกอบ
        </div>
      )
    };

    const handleQuillChange = (content) => {
      // Update the form field value with the Quill content
      console.log(content)
      getFieldDecorator('DetailReply', { initialValue: content });
    };

    const TypeCarText = (data) => {
      let TypeCar = data.TypeCar;
      let Plate1 = data.Plate1
      if ((TypeCar == "" || TypeCar === undefined) && Plate1) return ('รถขนส่ง');
      let TypeCarText = vehicleTypeData.find((item) => item.id === TypeCar);
      if (TypeCarText === undefined) {
        return TypeCar;
      }
      return TypeCarText.name;
    }

    return (

      <CustomModal
        title={data ? 'จัดการแจ้งปัญหา' : 'เพิ่ม'}
        visible={visible}
        style={{ minWidth: '1100px', top: '20px' }}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            บันทึก
          </Button>,
        ]}
      >

        <Descriptions title="ข้อมูลการแจ้งปัญหา">
          <Descriptions.Item label="รหัสอ้างอิง" span={2}>{data && data.RefCode}</Descriptions.Item>
          <Descriptions.Item label="วันเวลาได้รับปัญหา">{data && convertToDateBE(data.created)}</Descriptions.Item>
          <Descriptions.Item label="ชื่อผู้ใช้งาน" span={2}>{data && data.ReporterName}</Descriptions.Item>
          <Descriptions.Item label="เวลา">{data && moment(data.created).format('HH:mm:ss')}</Descriptions.Item>
        </Descriptions>
        <Descriptions title="ข้อมูลการแจ้งปัญหา">
          <Descriptions.Item label="รายการปัญหา" span={3}>{data && data.TitleReport}</Descriptions.Item>
          <Descriptions.Item label="ทะเบียนรถ (ถ้ามี)">
            {data && `${data.Plate1}${data.TypeCar == "" ? '-' : ' '}${data.Plate2}`}
          </Descriptions.Item>
          <Descriptions.Item label="จังหวัด">{data && data.OffLocDesc}</Descriptions.Item>
          <Descriptions.Item label="ประเภทรถ">{data && TypeCarText(data)}</Descriptions.Item>
          <Descriptions.Item label="รายละเอียด" span={3}></Descriptions.Item>
          <Descriptions.Item label="" span={3}>
            {data && (
              <pre style={{ whiteSpace: 'pre-wrap' }}>{data.DetailReport}</pre>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="รูปภาพ" span={3} style={styles.descriptionsItem}>
          </Descriptions.Item>
          <Descriptions.Item span={1}>
          </Descriptions.Item>
          <Descriptions.Item span={2}>

            {ImageReport?.length > 0 ? (
              <List
                dataSource={ImageReport}
                renderItem={renderFileItem}
                itemLayout="vertical"
                style={styles.fileList}
              />
            ) : (
              renderEmpty()
            )}
          </Descriptions.Item>
        </Descriptions>

        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
          <Spin spinning={formLoading}>
            <Descriptions title="การตอบกลับการแจ้งปัญหา">
              <Form.Item label="         สถานะการแก้ไข" span={3} >
                {getFieldDecorator('StatusReport', {
                  initialValue: !data || !data.StatusReport || data.StatusReport === '' ? "waiting" : data.StatusReport,
                })(
                  <Radio.Group>
                    <Radio value="waiting" style={{ color: "orange" }}>รอดำเนินการ</Radio>
                    <Radio value="done" style={{ color: "green" }}>ดำเนินการเรียบร้อย</Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              <Descriptions.Item span={3}>
                <Form.Item label=" รายละเอียดการตอบกลับ">
                  {getFieldDecorator('DetailReply', {
                    initialValue: get(data, ['DetailReply']),
                    rules: [
                      {
                        required: true,
                        validator: (rule, value, callback) => {
                          if (!value || value.trim() === '') {
                            callback('กรุณากรอกคำอธิบาย');
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  })(<QuillEditor style={{ height: '300px', width: '750px', marginBottom: '90px' }} initialValue={get(data, ['DetailReply'])} onChange={handleQuillChange} />)}
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Spin>
        </Form>
      </CustomModal>
    );
  },
);

const styles = {
  fileListContainer: {
    width: '100%',
    maxWidth: '100%',
  },
  fileList: {
    width: '100%',
    maxWidth: '100%',
  },
  fileItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '8px',
  },
  leftContent: {
    display: 'flex',
    alignItems: 'center',
  },
  fileIcon: {
    fontSize: '48px', // Adjust the icon size as desired
    marginRight: '8px',
  },
  fileName: {
    fontWeight: 'bold',
  },
  rightContent: {
    marginLeft: 'auto',
  },
  emptyContainer: {
    textAlign: 'center',
    padding: '16px',
    fontSize: '16px',
    color: '#999',
  },
  descriptionsItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};

export const CustomModal = styled(Modal)`
  #kiosksIds {
    display: inline-block;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 50%;
  }
`;